import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '../../global';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { BlockStandard } from '../../components/cvt/layout/block-standard';
import { HtmlContent } from '../../components/basic/html-content';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import PageblockPhotoHalf from '../../components/pageBlockPhotoHalf';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const MobileLoesungenPageTemplate = ({
  title,
  subtitle,
  image,
  mobileOverview,
  mobileStory,
  mobileSpotlight,
}: {
  title: string;
  subtitle: string;
  image: any;
  mobileOverview: any;
  mobileStory: any;
  mobileSpotlight: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h2>{mobileOverview.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(mobileOverview.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: CvtColorSchema.bgGray,
          color: CvtColorSchema.fontDarkGray,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={mobileStory.image}
            alt="Unternehmensgruppe"
            width="200px"
            height="auto"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>{mobileStory.headline}</h2>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(mobileStory.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!mobileSpotlight.image.childImageSharp
              ? mobileSpotlight.image.childImageSharp.fluid.src
              : mobileSpotlight.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {mobileSpotlight.subheadline}
        </h3>
        <h2
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {mobileSpotlight.headline}
        </h2>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(mobileSpotlight.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
    </section>
  );
};

MobileLoesungenPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  mobileOverview: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  mobileStory: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
  mobileSpotlight: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
};

const MobileLoesungenPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />

      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={7}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />

      <BlockStandard
        headline={frontmatter.mobileOverview.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgColor={CvtColorSchema.white}
        zIndex={6}
        padding={PaddingObj.PaddingIdle}
        textAlignment={CvtBasicTheme.align.left as any}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.mobileOverview.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>

      <PageblockPhotoHalf
        headline={frontmatter.mobileStory.headline}
        bgcolor={CvtColorSchema.bgGray}
        indexZ="5"
        imgposition="left"
        image={
          !frontmatter.mobileStory.image.childImageSharp &&
          frontmatter.mobileStory.image.extension === 'jpg'
            ? frontmatter.mobileStory.image.publicURL
            : frontmatter.mobileStory.image.publicURL
        }
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(frontmatter.mobileStory.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </PageblockPhotoHalf>

      <BlockStandard
        headline={frontmatter.mobileSpotlight.headline}
        subheadline={frontmatter.mobileSpotlight.subheadline}
        bgImage={
          !frontmatter.mobileSpotlight.image.childImageSharp &&
          frontmatter.mobileSpotlight.image.extension === 'svg'
            ? frontmatter.mobileSpotlight.image.publicURL
            : null
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={4}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontBlack}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.mobileSpotlight.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
    </Layout>
  );
};

MobileLoesungenPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MobileLoesungenPage;

export const mobileLoesungenPageQuery = graphql`
  query MobileLoesungenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        mobileOverview {
          content
          headline
        }
        mobileStory {
          headline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        mobileSpotlight {
          headline
          subheadline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
